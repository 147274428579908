import React from 'react'
import {
    createUseResponsiveStyles,
    centerStyle,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import { LineBreakText } from 'components/atoms/LineBreakText'

const RightColumn_ = () => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.rightColumn)}>
            <p className={classes.phoneText}>대표전화</p>
            <p className={classes.phoneNumberText}>1181-1942</p>
            <LineBreakText className={classes.openingHoursText}>
                {
                    '평일 오전 10시 - 오후5시\n(점심시간 : 평일 오후 1시 - 오후 2시)'
                }
            </LineBreakText>
        </div>
    )
}
export const RightColumn = React.memo(RightColumn_)

const useStyles = createUseResponsiveStyles({
    rightColumn: responsiveStyle(
        {
            ...centerStyle({ offsetRight: -960 + 237 }),
            textAlign: 'right',
        },
        {},
    ),
    phoneText: fontStyle(12, 15, 700, '#c0c0c0', 'Apple SD Gothic Neo'),
    phoneNumberText: {
        ...fontStyle(31, 37, 800, '#c0c0c0', 'Apple SD Gothic Neo'),
        marginTop: 2,
        marginBottom: 4,
    },
    openingHoursText: fontStyle(14, 21, 300, '#868686', 'Apple SD Gothic Neo'),
})
