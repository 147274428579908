import React, { useEffect, useState } from 'react'
import LOGO from 'assets/workinfo-logo.svg'
import {
    createUseResponsiveStyles,
    ResponsiveMethods,
    responsiveStyle,
} from 'utils/styles'

const RootHeader_ = () => {
    const [scrollY, setScrollY] = useState(0)
    const classes = useStyles({ isScrolled: scrollY !== 0 })

    const handleScroll = (ev: Event) => {
        setScrollY(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <header className={classes.header}>
            <div className={classes.headerBackground} />
            <img className={classes.logo} src={LOGO} />
        </header>
    )
}
export const RootHeader = React.memo(RootHeader_)

type UseStylesProp = ResponsiveMethods & {
    isScrolled: boolean
}
const useStyles = createUseResponsiveStyles({
    header: {
        position: 'fixed',
        width: '100%',
        height: responsiveStyle(66, 56),
        display: 'flex',
        alignItems: 'center',
        borderBottomStyle: 'solid',
        zIndex: 100,
    },
    logo: {
        position: 'absolute',
        left: responsiveStyle('calc(50% - 550px)', 'calc(50% - 164px)'),
        zIndex: 1,
        transform: 'translateZ(0)',
        height: responsiveStyle(24, 18),
    },
    headerBackground: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: ({ isScrolled }: UseStylesProp) => (isScrolled ? 1 : 0),
        width: '100%',
        height: '100%',
        transition: 'opacity 0.3s ease',
    },
})
