import React from 'react'
import {
    centerStyle,
    createUseResponsiveStyles,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import LOGO from 'assets/workinfo-logo-greyscale.svg'
import useBreakpoints from 'hooks/useBreakpoints'
import { FooterRow } from 'components/organisms/Footer/FooterRow'
import { CopyrightRow } from './CopyrightRow'
import { RightColumn } from './RightColumn'

const mobileFooterData = [
    ['(주)케이비자', '대표이사: 이상욱'],
    ['사업자등록번호: 438-88-01798'],
    ['주소: 서울 마포구 양화로 81 삼성화재빌딩 B1, 3-5층, 529호'],
    ['대표전화: 1811-1942', 'Email: official@workinfo.kr'],
    ['행정사업무신고번호: 3520000202169'],
    ['직업정보제공사업시고번호: J1500020210002'],
]
const desktopFooterData = [
    ['(주)케이비자', '대표이사: 이상욱', '사업자등록번호: 438-88-01798'],
    [
        '주소: 서울 마포구 양화로 81 삼성화재빌딩 B1, 3-5층, 529호',
        '대표전화: 1811-1942',
        'Email: official@workinfo.kr',
    ],
    [
        '행정사업무신고번호: 3520000202169',
        '직업정보제공사업시고번호: J1500020210002',
    ],
]

const Footer_ = () => {
    const classes = useStyles()
    const isDesktop = useBreakpoints('desktop')
    const footerData = isDesktop ? desktopFooterData : mobileFooterData

    return (
        <footer className={classNames(classes.footer)}>
            <div className={classNames(classes.footerWrapper)}>
                <div className={classNames(classes.leftColumn)}>
                    <img className={classNames(classes.logo)} src={LOGO} />
                </div>
                <div className={classNames(classes.middleColumn)}>
                    {footerData.map((footerRowTexts, index) => (
                        <FooterRow key={index} texts={footerRowTexts} />
                    ))}
                    <CopyrightRow />
                </div>
                <RightColumn />
            </div>
        </footer>
    )
}
export const Footer = React.memo(Footer_)

const useStyles = createUseResponsiveStyles({
    footer: {
        height: responsiveStyle(200, 510),
        backgroundColor: '#383838',
        position: 'relative',
        display: 'flex',
    },
    footerWrapper: {
        width: responsiveStyle(1920, 360),
        margin: responsiveStyle(0, 'auto'),
        paddingLeft: responsiveStyle(0, 16),
    },

    leftColumn: responsiveStyle(centerStyle({ offsetLeft: -960 + 240 }), {}),
    logo: {
        height: responsiveStyle(25, 20),
        marginBottom: responsiveStyle(0, 30),
    },

    middleColumn: responsiveStyle(centerStyle({ offsetLeft: -960 + 434 }), {}),
})
