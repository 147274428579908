import React from 'react'
import PERSONALIZED_IMG from 'assets/img-personalized@3x.webp'
import PERSONALIZED_HEADER_IMG from 'assets/img-personalized-header.svg'
import { ImageSectionTemplate } from './ImageSectionTemplate'
import { createUseResponsiveStyles, responsiveStyle } from 'utils/styles'

const PersonalizedSection_ = () => {
    const classes = useStyles()

    return (
        <ImageSectionTemplate
            className={classes.section}
            imagePosition="left"
            imageSrc={PERSONALIZED_IMG}
            imageDimensions={[
                [356 * 1.519, 491 * 1.519],
                [356, 491],
            ]}
            headerImageSrc={PERSONALIZED_HEADER_IMG}
            header="맞춤형 정보제공"
            subHeader="출입국 정책부터 생활정보까지"
            body={
                '외국인 등록증을 기반으로\n입국부터 출국까지 필요한 맞춤 정보를 제공'
            }
            backgroundColor="#f6f6f6"
        />
    )
}
export const PersonalizedSection = React.memo(PersonalizedSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        height: responsiveStyle(940, '844px !important'),
    },
})
