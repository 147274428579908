import React from 'react'
import { Section } from 'components/atoms/Section'
import { HeaderText } from 'components/atoms/HeaderText'
import { CTAButton } from 'components/atoms/CTAButton'
import BACKGROUND from 'assets/bg-hero.webp'
import { createUseResponsiveStyles, responsiveStyle } from 'utils/styles'
import useBreakpoints from 'hooks/useBreakpoints'

const HeroSection_ = () => {
    const classes = useStyles()
    const isDesktop = useBreakpoints('desktop')

    return (
        <Section className={classes.section}>
            <HeaderText type="h1" color="white" shadow>
                취업외국인을 위한
                <br />
                생활공간 ‘워크인포’
            </HeaderText>
            <HeaderText
                className={classes.subHeader}
                type="h3"
                color="white"
                shadow
            >
                구인구직부터 비자발급까지 한번에
            </HeaderText>
            <CTAButton marginTop={isDesktop ? 26 : 30}>앱 다운로드</CTAButton>
        </Section>
    )
}
export const HeroSection = React.memo(HeroSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        'backgroundImage': `url(${BACKGROUND})`,
        'backgroundSize': 'cover',
        'backgroundPosition': 'center',
        'backgroundColor': 'rgba(0,0,0,.2)',
        'backgroundBlendMode': 'multiply',
        'height': responsiveStyle(1080, 640),
        'textAlign': 'center',

        '& > div': {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    subHeader: { marginTop: 14, fontWeight: 500 },
})
