import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'

export interface FooterRowProps {
    texts: string[]
}

const FooterRow_ = ({ texts }: FooterRowProps) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <div className={classNames(classes.footerRow)}>
                {texts.map((text, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && (
                            <div className={classes.footerDivider} />
                        )}
                        <span className={classes.footerText}>
                            {text.includes('대표전화') ? (
                                <React.Fragment>
                                    {text.split(':')[0]}:
                                    <span style={{ letterSpacing: -1 }}>
                                        {text.split(':')[1]}
                                    </span>
                                </React.Fragment>
                            ) : (
                                text
                            )}
                        </span>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    )
}
export const FooterRow = React.memo(FooterRow_)

const useStyles = createUseResponsiveStyles({
    footerRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
    },
    footerText: fontStyle(14, 17, 400, '#c0c0c0', 'Apple SD Gothic Neo'),
    footerDivider: {
        display: 'inline-block',
        width: 1,
        height: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        margin: responsiveStyle('0 18px', '0 14px'),
    },
})
