import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import { Section } from 'components/atoms/Section'
import { HeaderText } from 'components/atoms/HeaderText'
import { LineBreakText } from 'components/atoms/LineBreakText'
import ENDING_IMG from 'assets/img-ending.svg'
import useBreakpoints from 'hooks/useBreakpoints'

const EndingSection_ = () => {
    const classes = useStyles()
    const isDesktop = useBreakpoints('desktop')

    return (
        <Section className={classNames(classes.section)} color="#e0edff">
            <HeaderText type="h2">우리는 함께 성장합니다.</HeaderText>
            <LineBreakText className={classNames(classes.paragraph)}>
                {isDesktop
                    ? '워크인포 서비스를 더욱 더 사랑하고 가치있게 사용하기 바라며,\n이들을 위한 마음으로 즐겁게 일합니다.'
                    : '워크인포 서비스를 더욱 더 사랑하고\n가치있게 사용하기 바라며,\n이들을 위한 마음으로 즐겁게 일합니다.'}
            </LineBreakText>
            <img className={classNames(classes.img)} src={ENDING_IMG} />
        </Section>
    )
}
export const EndingSection = React.memo(EndingSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        'textAlign': 'center',
        'height': responsiveStyle(706, 460),
        '& > div': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    },

    paragraph: responsiveStyle(
        {
            ...fontStyle(24, 36, 500, '#616161'),
            marginTop: 30,
        },
        {
            ...fontStyle(16, 24, 500, '#616161'),
            marginTop: 26,
        },
    ),

    img: {
        width: responsiveStyle(664, 360),
        height: responsiveStyle(344, 186),
        justifySelf: 'flex-end',
        marginTop: responsiveStyle(70, 60),
    },
})
