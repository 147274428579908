import React from 'react'
import { createUseResponsiveStyles, responsiveStyle } from 'utils/styles'
import classNames from 'classnames'
import { Section } from 'components/atoms/Section'
import { HeaderText } from 'components/atoms/HeaderText'
import { IntroCard } from 'components/molecules/IntroCard'
import INTROCARD_IMG1 from 'assets/introcard-1.svg'
import INTROCARD_IMG2 from 'assets/introcard-2.svg'
import INTROCARD_IMG3 from 'assets/introcard-3.svg'

const IntroSection_ = () => {
    const classes = useStyles()

    return (
        <Section className={classNames(classes.section)} color="#f1f4f9">
            <HeaderText
                className={classNames(classes.subHeader)}
                type="h3"
                color="#6b6b6b"
            >
                서비스 소개
            </HeaderText>
            <HeaderText className={classes.header} type="h2" color="#272727">
                취업생활은 워크인포에서
            </HeaderText>
            <div className={classes.cardArea}>
                <IntroCard
                    title="무료 구인구직"
                    body={
                        '국내부터 해외까지 언제 어디서든\n구인구직이 가능합니다.'
                    }
                    imgSrc={INTROCARD_IMG1}
                />
                <IntroCard
                    title="취업비자 서비스"
                    body={'전문가와 함께하는 출입국 업무\n서비스를 받아보세요.'}
                    imgSrc={INTROCARD_IMG2}
                />
                <IntroCard
                    title="취업생활 정보"
                    body={
                        '취업 외국인을 위한 커뮤니티와\n생활정보를 제공합니다.'
                    }
                    imgSrc={INTROCARD_IMG3}
                />
            </div>
        </Section>
    )
}
export const IntroSection = React.memo(IntroSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        height: responsiveStyle(900, 1446),
    },
    subHeader: { fontWeight: 500 },
    header: { marginTop: responsiveStyle(12, 6) },
    cardArea: {
        display: 'flex',
        flexDirection: responsiveStyle('row', 'column'),
        gap: responsiveStyle(25, 24),
        marginTop: responsiveStyle(60, 40),
        alignItems: 'center',
    },
})
