import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import { LineBreakText } from 'components/atoms/LineBreakText'

export interface IntroCardProps {
    title: string
    body: string
    imgSrc: string
}

const IntroCard_ = ({ title, body, imgSrc }: IntroCardProps) => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.div)}>
            <img className={classNames(classes.img)} src={imgSrc} />
            <div className={classNames(classes.textArea)}>
                <p className={classNames(classes.title)}>{title}</p>
                <LineBreakText className={classNames(classes.body)}>
                    {body}
                </LineBreakText>
            </div>
        </div>
    )
}
export const IntroCard = React.memo(IntroCard_)

const useStyles = createUseResponsiveStyles({
    div: {
        width: responsiveStyle(350, 320),
        height: responsiveStyle(430, 392),
        borderRadius: 12,
        backgroundColor: 'white',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    img: {
        height: responsiveStyle(268, 250),
        marginTop: -3,
    },

    textArea: {
        flexGrow: 1,
        paddingLeft: responsiveStyle(40, 20),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        position: 'relative',
        top: -2,
    },
    title: responsiveStyle(
        { ...fontStyle(24, 36, 700, '#272727'), marginBottom: 11 },
        { ...fontStyle(20, 29, 700, '#272727'), marginBottom: 9 },
    ),
    body: responsiveStyle(
        { ...fontStyle(18, 28, 400, '#444444') },
        { ...fontStyle(16, 24, 400, '#444444') },
    ),
})
