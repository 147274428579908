import React from 'react'
import ReactDOM from 'react-dom'
import 'reset.css'
import 'index.css'
import App from './App'
import { breakpoints } from 'hooks/useBreakpoints'

const rootElement = document.getElementById('root') as HTMLElement
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    rootElement,
)

const rootStyle = rootElement.style as unknown as { zoom: number }
window.addEventListener('resize', scaleToFit)

function scaleToFit() {
    if (window.innerWidth < breakpoints.mobileScaleMax) {
        rootStyle.zoom = window.innerWidth / breakpoints.mobileDesign
    } else if (window.innerWidth < breakpoints.desktop) {
        rootStyle.zoom = breakpoints.mobileScaleMax / breakpoints.mobileDesign
    } else if (window.innerWidth < breakpoints.desktopDesign) {
        rootStyle.zoom = window.innerWidth / breakpoints.desktopDesign
    } else {
        rootStyle.zoom = 1
    }
}

scaleToFit()
