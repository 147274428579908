import React, { CSSProperties } from 'react'
import useBreakpoints from 'hooks/useBreakpoints'
import { fontStyle } from 'utils/styles'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

export interface HeaderTextProps {
    className?: string
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    color?: string
    style?: CSSProperties
    children?: React.ReactNode
    shadow?: boolean
}

const HeaderText_ = ({
    type,
    color,
    style = {},
    children = '',
    shadow,
    className,
}: HeaderTextProps) => {
    const isDesktop = useBreakpoints('desktop')
    const desktopClasses = useDesktopStyles()
    const mobileClasses = useMobileStyles()
    const classes = isDesktop ? desktopClasses : mobileClasses
    const customStyle = color ? { ...style, color } : style

    return React.createElement(
        type,
        {
            className: classNames(className, classes[type], {
                [classes.shadow]: shadow,
            }),
            style: customStyle,
        },
        children,
    )
}
export const HeaderText = React.memo(HeaderText_)

const useDesktopStyles = createUseStyles({
    h1: fontStyle(64, 90, 900, 'black'),
    h2: fontStyle(44, 65, 700, 'black'),
    h3: fontStyle(24, 32, 700, 'black'),
    h4: fontStyle(26, 38, 500, 'black'),
    h5: fontStyle(16, 24, 400, 'red'),
    h6: fontStyle(14, 17, 400, 'red'),
    shadow: {
        textShadow: '0px 2px 20px rgba(0, 0, 0, 0.5)',
    },
})

const useMobileStyles = createUseStyles({
    h1: fontStyle(32, 50, 900, 'black'),
    h2: fontStyle(24, 36, 700, 'black'),
    h3: fontStyle(20, 29, 700, 'black'),
    h4: fontStyle(18, 27, 500, 'black'),
    h5: fontStyle(16, 24, 400, 'red'),
    h6: fontStyle(14, 17, 400, 'red'),
    shadow: {
        textShadow: '0px 2px 20px rgba(0, 0, 0, 0.5)',
    },
})
