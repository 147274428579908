import React from 'react'
import VISA_IMG from 'assets/img-visa@3x.webp'
import VISA_HEADER_IMG from 'assets/img-visa-header.svg'
import { ImageSectionTemplate } from './ImageSectionTemplate'

const VisaSection_ = () => {
    return (
        <ImageSectionTemplate
            imagePosition="left"
            imageSrc={VISA_IMG}
            imageDimensions={[
                [380, 770],
                [257, 520],
            ]}
            headerImageSrc={VISA_HEADER_IMG}
            header="취업비자 서비스"
            subHeader="15가지 출입국 업무"
            body={
                '현재비자와 신청비자를 선택하여\n비자 상담부터 발급까지 한번에'
            }
            backgroundColor="#f6f6f6"
        />
    )
}
export const VisaSection = React.memo(VisaSection_)
