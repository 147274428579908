import React from 'react'
import RECRUIT_IMG from 'assets/img-recruit@3x.webp'
import RECRUIT_HEADER_IMG from 'assets/img-recruit-header.svg'
import { ImageSectionTemplate } from './ImageSectionTemplate'
import { createUseResponsiveStyles, responsiveStyle } from 'utils/styles'

const RecruitSection_ = () => {
    const classes = useStyles()

    return (
        <ImageSectionTemplate
            className={classes.section}
            imagePosition="right"
            imageSrc={RECRUIT_IMG}
            imageDimensions={[
                [770 * (320 / 460), 770],
                [320, 460],
            ]}
            headerImageSrc={RECRUIT_HEADER_IMG}
            header="외국인 구인구직 서비스"
            subHeader="업종별로 손쉽게 등록완료"
            body={'취업이 가능한 외국인부터\n잠재적 취업 대상자까지'}
        />
    )
}
export const RecruitSection = React.memo(RecruitSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        height: responsiveStyle(940, '849px !important'),
    },
})
