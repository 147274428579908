import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import useBreakpoints from 'hooks/useBreakpoints'

const CopyrightRow_ = () => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <div className={classNames(classes.copyrightRow)}>
                <span className={classNames(classes.copyrightText)}>
                    서비스 이용약관
                </span>
                <span className={classNames(classes.copyrightText)}>
                    개인정보 처리방침
                </span>
                {useBreakpoints('desktop') || <br />}
                <span className={classNames(classes.copyrightText)}>
                    COPYRIGHT (C) 2022 by (주)K-VISA
                </span>
            </div>
        </React.Fragment>
    )
}
export const CopyrightRow = React.memo(CopyrightRow_)

const useStyles = createUseResponsiveStyles({
    copyrightRow: {
        marginTop: responsiveStyle(19 - 10, 30),
        marginBottom: responsiveStyle(0, 14),
    },
    copyrightText: {
        display: 'inline-block',
        ...fontStyle(14, 17, 400, '#787878', 'Apple SD Gothic Neo'),
        marginRight: responsiveStyle(22, 20),
        marginBottom: responsiveStyle(0, 16),
    },
})
