import React from 'react'
import {
    createUseResponsiveStyles,
    responsiveStyle,
    ResponsiveMethods,
    fontStyle,
} from 'utils/styles'
import classNames from 'classnames'
import { Section } from 'components/atoms/Section'
import { HeaderText } from 'components/atoms/HeaderText'
import { LineBreakText } from 'components/atoms/LineBreakText'
import useBreakpoints from 'hooks/useBreakpoints'

export interface ImageSectionTemplateProps {
    imagePosition: 'left' | 'right'
    imageSrc: string
    imageDimensions: [[number, number], [number, number]]
    headerImageSrc: string
    header: string
    subHeader: string
    body: string
    backgroundColor?: string
    className?: string
}

const ImageSectionTemplate_ = ({
    imagePosition,
    imageSrc,
    imageDimensions,
    headerImageSrc,
    header,
    subHeader,
    body,
    backgroundColor,
    className,
}: ImageSectionTemplateProps) => {
    const classes = useStyles({
        imagePosition,
        imageDimensions,
        backgroundColor,
    })
    const isDesktop = useBreakpoints('desktop')
    const shouldPositionImageRight = !isDesktop || imagePosition === 'right'

    return (
        <Section className={classNames(classes.section, className)}>
            {!shouldPositionImageRight && (
                <img className={classNames(classes.img)} src={imageSrc} />
            )}
            <div
                style={
                    shouldPositionImageRight
                        ? {}
                        : { position: 'absolute', left: 'calc(50% + 80px)' }
                }
            >
                <img
                    className={classNames(classes.headerImage)}
                    src={headerImageSrc}
                />
                <HeaderText className={classNames(classes.header)} type="h2">
                    {header}
                </HeaderText>
                <HeaderText
                    className={classNames(classes.subHeader)}
                    type="h4"
                    color="#4a8eec"
                >
                    {subHeader}
                </HeaderText>
                <LineBreakText className={classNames(classes.paragraph)}>
                    {body}
                </LineBreakText>
            </div>
            {shouldPositionImageRight && (
                <img className={classNames(classes.img)} src={imageSrc} />
            )}
        </Section>
    )
}
export const ImageSectionTemplate = React.memo(ImageSectionTemplate_)

type UseStylesProp = Pick<
    ImageSectionTemplateProps,
    'imagePosition' | 'imageDimensions' | 'backgroundColor'
> &
    ResponsiveMethods
const useStyles = createUseResponsiveStyles({
    section: {
        'height': responsiveStyle(940, 947),
        '& > div': {
            display: 'flex',
            flexDirection: responsiveStyle('row', 'column'),
            alignItems: 'center',
            position: 'relative',
        },
        'backgroundColor': (props: UseStylesProp) =>
            props.backgroundColor ?? 'white',
        'textAlign': responsiveStyle('left', 'center'),
    },

    headerArea: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerImage: responsiveStyle(
        { width: 120, height: 120 },
        { width: 60, height: 60 },
    ),
    header: { marginTop: responsiveStyle(40, 20) },
    subHeader: { marginTop: responsiveStyle(14, 6) },
    paragraph: responsiveStyle(
        {
            marginTop: 40,
            ...fontStyle(22, 34, 400, '#444444'),
        },
        {
            marginTop: 22,
            ...fontStyle(16, 26, 400, '#444444'),
        },
    ),

    img: ({ isDesktop, imageDimensions, imagePosition }: UseStylesProp) => {
        const imageDimension = imageDimensions[isDesktop ? 0 : 1]
        return {
            width: imageDimension[0],
            height: imageDimension[1],
            ...(isDesktop
                ? {
                      [imagePosition === 'right'
                          ? 'marginLeft'
                          : 'marginRight']: 'auto',
                  }
                : {
                      marginTop: 46,
                  }),
        }
    },
})
