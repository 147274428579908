import { HeroSection } from 'components/organisms/HeroSection'
import { IntroSection } from 'components/organisms/IntroSection'
import { RecruitSection } from 'components/organisms/RecruitSection'
import { VisaSection } from 'components/organisms/VisaSection'
import { CommunitySection } from 'components/organisms/CommunitySection'
import { PersonalizedSection } from 'components/organisms/PersonalizedSection'
import { PartnerSection } from 'components/organisms/PartnerSection'
import { EndingSection } from 'components/organisms/EndingSection'
import { Footer } from 'components/organisms/Footer'
import { RootHeader } from 'components/organisms/RootHeader'

function App() {
    return (
        <div className="App">
            <RootHeader />
            <HeroSection />
            <IntroSection />
            <RecruitSection />
            <VisaSection />
            <CommunitySection />
            <PersonalizedSection />
            <PartnerSection />
            <EndingSection />
            <Footer />
        </div>
    )
}

export default App
