import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    ResponsiveMethods,
} from 'utils/styles'

export interface CTAButtonProps {
    children?: string
    marginTop?: number
}

const CTAButton_ = ({ children, marginTop = 0 }: CTAButtonProps) => {
    const classes = useStyles({ marginTop })

    return (
        <button className={classes.button}>
            <span className={classes.text}>{children}</span>
        </button>
    )
}
export const CTAButton = React.memo(CTAButton_)

type UseStylesProp = { marginTop: number } & ResponsiveMethods
const useStyles = createUseResponsiveStyles({
    button: ({ isDesktop, marginTop }: UseStylesProp) => ({
        'width': isDesktop ? 180 : 126,
        'height': isDesktop ? 70 : 50,
        'borderRadius': isDesktop ? 35 : 25,
        'backgroundColor': '#1e77f2',
        '&:hover': {
            backgroundColor: '#1e76f2c8',
        },
        marginTop,
        'transition': 'all 0.15s ease',
    }),
    text: ({ isDesktop }) => ({
        ...(isDesktop
            ? fontStyle(20, 29, 700, 'white')
            : fontStyle(16, 24, 700, 'white')),
        position: 'relative',
        top: -1.5,
    }),
})
