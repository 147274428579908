import React, { HTMLProps } from 'react'
import { createUseResponsiveStyles } from 'utils/styles'
import classNames from 'classnames'

export interface LineBreakTextProps extends HTMLProps<HTMLParagraphElement> {
    children?: string
}

const LineBreakText_ = ({ children, ...props }: LineBreakTextProps) => {
    const classes = useStyles()

    return (
        <p className={classNames(classes.text)} {...props}>
            {children?.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {index !== 0 && <br />}
                    {line}
                </React.Fragment>
            ))}
        </p>
    )
}
export const LineBreakText = React.memo(LineBreakText_)

const useStyles = createUseResponsiveStyles({
    text: {},
})
