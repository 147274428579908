import React from 'react'
import COMMUNITY_IMG from 'assets/img-community@3x.webp'
import COMMUNITY_HEADER_IMG from 'assets/img-community-header.svg'
import { ImageSectionTemplate } from './ImageSectionTemplate'

const CommunitySection_ = () => {
    return (
        <ImageSectionTemplate
            imagePosition="right"
            imageSrc={COMMUNITY_IMG}
            imageDimensions={[
                [380, 770],
                [257, 520],
            ]}
            headerImageSrc={COMMUNITY_HEADER_IMG}
            header="취업생활 커뮤니티"
            subHeader="함께 소통하는 취업 외국인들의 생활공간"
            body={'한국생활부터 취업정보까지\n다양한 정보를 공유하는 커뮤니티'}
        />
    )
}
export const CommunitySection = React.memo(CommunitySection_)
