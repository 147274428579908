import useBreakpoints from 'hooks/useBreakpoints'
import type { JssStyle, JssValue, StyleSheetFactoryOptions } from 'jss'
import { CSSProperties, useMemo } from 'react'
import { createUseStyles, Styles, Theming } from 'react-jss'

export const fontStyle = (
    fontSize: NonNullable<CSSProperties['fontSize']>,
    lineHeight: number,
    fontWeight: NonNullable<CSSProperties['fontWeight']>,
    color: NonNullable<CSSProperties['color']>,
    fontFamily: string | null = null,
): JssStyle => {
    return {
        fontFamily: fontFamily && [
            fontFamily,
            'Apple SD Gothic Neo',
            'AppleSDGothicNeo',
            '애플 SD 산돌고딕 Neo',
            'AppleSDGothicNeoWeb',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ],
        fontSize,
        lineHeight: `${lineHeight}px`,
        fontWeight,
        color,
    }
}

export const centerStyle = ({
    offsetLeft,
    offsetRight,
    offsetTop,
    offsetBottom,
}: Partial<
    Record<'offsetLeft' | 'offsetRight' | 'offsetTop' | 'offsetBottom', number>
>) => ({
    width: offsetTop || offsetBottom ? '100%' : 'auto',
    height: offsetLeft || offsetRight ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: offsetLeft ? `calc(50% + ${offsetLeft}px)` : 'auto',
    right: offsetRight ? `calc(50% + ${offsetRight}px)` : 'auto',
    top: offsetTop ? `calc(50% + ${offsetTop}px)` : 'auto',
    bottom: offsetBottom ? `calc(50% + ${offsetBottom}px)` : 'auto',
})

export interface ResponsiveMethods {
    isDesktop: boolean
    // isDesktop() boolean
}

export const createUseResponsiveStyles = <
    C extends string = string,
    Props = unknown,
    Theme = Jss.Theme,
>(
    styles:
        | Styles<C, Props & ResponsiveMethods, Theme>
        | ((theme: Theme) => Styles<C, Props, undefined>),
    options?: StyleSheetFactoryOptions & {
        index?: number
        theming?: Theming<Theme>
        name?: string
    },
) => {
    return function useResponsiveStyles(
        defaultProps?: Omit<Props, 'theme'> & { theme?: Theme },
    ) {
        const isDesktop = useBreakpoints('desktop')
        const responsiveMethods = useMemo(() => ({ isDesktop }), [isDesktop])
        const enhancedProps = useMemo(
            () => ({ ...defaultProps, ...responsiveMethods }),
            [defaultProps, responsiveMethods],
        ) as Props & ResponsiveMethods
        return createUseStyles(styles, options)(enhancedProps)
    }
}

interface ResponsiveStyle {
    (desktopStyle: JssValue, mobileStyle: JssValue): (
        responsiveMethods: ResponsiveMethods,
    ) => JssValue

    (desktopStyle: JssStyle, mobileStyle: JssStyle): (
        responsiveMethods: ResponsiveMethods,
    ) => JssStyle
}

export const responsiveStyle: ResponsiveStyle = (desktopStyle, mobileStyle) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (({ isDesktop }: ResponsiveMethods) =>
        isDesktop ? desktopStyle : mobileStyle) as any
