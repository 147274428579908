import React from 'react'
import {
    createUseResponsiveStyles,
    fontStyle,
    responsiveStyle,
} from 'utils/styles'
import classNames from 'classnames'
import { Section } from 'components/atoms/Section'
import BACKGROUND from 'assets/bg-hero.webp'
import { CTAButton } from 'components/atoms/CTAButton'
import useBreakpoints from 'hooks/useBreakpoints'

const PartnerSection_ = () => {
    const classes = useStyles()
    const isDesktop = useBreakpoints('desktop')

    return (
        <Section className={classNames(classes.section)}>
            <p className={classNames(classes.header)}>
                <span className={classNames(classes.headerBold)}>
                    ‘130만 취업 외국인들의 평범한 일상’
                </span>
                을<br />
                함께 만들어갈 파트너를 기다리고 있습니다.
            </p>
            <CTAButton marginTop={isDesktop ? 40 : 30}>제휴 문의</CTAButton>
        </Section>
    )
}
export const PartnerSection = React.memo(PartnerSection_)

const useStyles = createUseResponsiveStyles({
    section: {
        backgroundImage: `url(${BACKGROUND})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0,0,0,.5)',
        backgroundBlendMode: 'multiply',
        height: responsiveStyle(440, 280),
        textAlign: responsiveStyle('left', 'center'),
    },
    header: responsiveStyle(
        fontStyle(32, 50, 400, 'white'),
        fontStyle(18, 30, 400, 'white'),
    ),
    headerBold: { fontWeight: 700 },
})
