import React, { HTMLProps } from 'react'
import classNames from 'classnames'
import { createUseResponsiveStyles, responsiveStyle } from 'utils/styles'

export interface SectionProps extends HTMLProps<HTMLElement> {
    children?: React.ReactNode
    color?: string
}

const Section_ = ({ color, className, children, ...props }: SectionProps) => {
    const classes = useStyles({ color })

    return (
        <section className={classNames(classes.section, className)} {...props}>
            <div className={classes.contentArea}>{children}</div>
        </section>
    )
}
export const Section = React.memo(Section_)

const useStyles = createUseResponsiveStyles({
    section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ({ color }: { color?: string }) => color,
    },
    contentArea: responsiveStyle(
        {
            width: 1100,
        },
        {
            width: 360,
            padding: [0, 16],
        },
    ),
})
