import { useMediaQuery } from 'react-responsive'

export const breakpoints = {
    desktopDesign: 1920,
    desktop: 1280,
    mobileScaleMax: 500,
    mobileDesign: 360,
}

export default function (breakpoint: 'mobile' | 'desktop') {
    switch (breakpoint) {
        case 'desktop':
            return useMediaQuery({
                query: `(min-width: ${breakpoints.desktop}px)`,
            })
        case 'mobile':
            return useMediaQuery({
                query: `(max-width: ${breakpoints.desktop}px)`,
            })
    }
}
